<template>
  <section>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col
                    cols="6"
                    lg="2"
                  >
                    <h5 class="text-capitalize">
                      Title
                    </h5>
                    <template v-if="lead?.title">
                      <div>
                        <b-card-text> {{ lead?.title }}</b-card-text>
                      </div>
                    </template></b-col>
                  <b-col
                    cols="6"
                    lg="2"
                  >
                    <h5 class="text-capitalize">
                      Key Contact Person
                    </h5>
                    <template v-if="lead?.key_contact_person">
                      <div>
                        <b-card-text>
                          {{ lead?.key_contact_person }}</b-card-text>
                      </div>
                    </template>
                  </b-col>
                  <b-col
                    cols="6"
                    lg="2"
                  >
                    <h5 class="text-capitalize">
                      Company Name
                    </h5>
                    <template v-if="lead?.company_name">
                      <div>
                        <b-card-text> {{ lead?.company_name }}</b-card-text>
                      </div>
                    </template>
                  </b-col>
                  <b-col
                    cols="6"
                    lg="2"
                  >
                    <div>
                      <h5 class="text-capitalize">
                        Email
                      </h5>
                      <template v-if="lead?.email">
                        <b-link :href="'mailto:' + lead?.email">
                          {{ lead?.email }}
                        </b-link>
                        <!-- <b-card-text> {{ lead?.email }}</b-card-text> -->
                      </template>
                      <template v-else-if="!lead?.email">
                        <span>N/A</span></template>
                    </div>
                  </b-col>

                  <b-col
                    cols="6"
                    lg="2"
                  >
                    <div>
                      <h5 class="text-capitalize">
                        Web Site Link
                      </h5>
                      <template v-if="lead?.website_link">
                        <b-link
                          :href="lead?.website_link"
                          target="_blank"
                        >
                          {{ lead?.website_link }}</b-link>
                      </template>
                      <template v-else-if="!lead?.website_link">
                        N/A
                      </template>
                    </div>
                  </b-col>
                  <b-col
                    cols="6"
                    lg="2"
                  >
                    <h5 class="text-capitalize">
                      Status
                    </h5>
                    <template v-if="lead?.lead_status_id">
                      <b-card-text>
                        <b-badge
                          pill
                          :style="{
                            background: lead?.leadStatus?.data?.color_code,
                          }"
                        >{{ lead?.leadStatus?.data?.name }}</b-badge>
                        <template
                          v-if="
                            lead?.leadStatus?.data?.type == 2 &&
                            lead?.estimation_sent == false
                          "
                        >
                          <b-badge
                            class="badge-glow mt-1"
                            variant="danger"
                          >Estimation Not Sent</b-badge>
                        </template>
                        <template
                          v-else-if="
                            lead?.leadStatus?.data?.type == 2 &&
                            lead?.estimation_sent
                          "
                        >
                          <b-badge
                            class="badge-glow mt-1"
                            variant="success"
                          >Estimation Sent</b-badge>
                        </template>
                      </b-card-text>
                    </template>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="6"
                    lg="2"
                  >
                    <div class="mt-2">
                      <h5 class="text-capitalize">
                        Source
                      </h5>
                      <template v-if="lead?.lead_source_id">
                        <b-card-text>
                          {{ lead?.leadSource?.data?.name }}
                        </b-card-text>
                      </template>
                    </div>
                  </b-col>
                  <b-col
                    cols="6"
                    lg="2"
                  >
                    <div class="mt-2">
                      <h5 class="text-capitalize">
                        Category
                      </h5>
                      <template v-if="lead?.lead_category_id">
                        <b-card-text>
                          {{ lead?.leadCategory?.data?.name }}
                        </b-card-text>
                      </template>
                    </div>
                  </b-col>
                  <b-col
                    cols="6"
                    lg="2"
                  >
                    <div class="mt-2">
                      <h5 class="text-capitalize">
                        Mobile
                      </h5>
                      <template v-if="lead?.mobile">
                        <b-link :href="'tel:' + lead?.mobile">
                          {{ lead?.mobile }}
                        </b-link>
                      </template>
                    </div>
                  </b-col>
                  <b-col
                    cols="6"
                    lg="2"
                  >
                    <div class="mt-2">
                      <h5 class="text-capitalize">
                        Tel
                      </h5>
                      <template v-if="lead?.phone">
                        <b-link :href="'tel:' + lead?.phone">
                          {{ lead?.phone }}
                        </b-link>
                      </template>
                      <template v-else>
                        <span>N/A</span>
                      </template>
                    </div>
                  </b-col>
                  <b-col
                    cols="6"
                    lg="2"
                  >
                    <div class="mt-2">
                      <h5 class="text-capitalize">
                        Last Contact Date & Time
                      </h5>
                      <template v-if="lead?.last_contact_date">
                        <b-card-text>
                          {{
                            lead?.last_contact_date
                                | moment("ddd, MMM Do YYYY, h:mm a")
                          }}
                        </b-card-text>
                      </template>
                    </div>
                  </b-col>
                  <b-col
                    cols="6"
                    lg="2"
                  >
                    <div class="mt-2">
                      <h5 class="text-capitalize">
                        Next Contact Date & Time
                      </h5>
                      <template v-if="lead?.next_contact_date">
                        <b-card-text>
                          {{
                            lead?.next_contact_date
                                | moment("ddd, MMM Do YYYY, h:mm a")
                          }}
                        </b-card-text>
                      </template>
                      <template
                        v-else-if="!lead?.next_contact_date"
                      >N/A</template>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="6"
                    lg="2"
                  >
                    <div class="mt-2">
                      <h5 class="text-capitalize">
                        Department
                      </h5>
                      <template v-if="lead?.department_id">
                        <b-card-text>
                          {{ lead?.department?.data?.name }}
                        </b-card-text>
                      </template>
                    </div>
                  </b-col>
                  <b-col
                    cols="6"
                    lg="2"
                  >
                    <div class="mt-2">
                      <h5 class="text-capitalize">
                        Assigned To
                      </h5>
                      <template v-if="lead?.team">
                        <b-card-text>
                          <span
                            v-for="(item, index) in lead.team.data"
                            :key="index"
                          >

                            <UserCard :user="item" />

                          </span>
                        </b-card-text>
                      </template>
                    </div>
                  </b-col>
                  <b-col
                    cols="6"
                    lg="2"
                  >
                    <div class="mt-2">
                      <h5 class="text-capitalize">
                        Address
                      </h5>
                      <template v-if="lead?.address">
                        <b-card-text>
                          {{ lead?.address }} <br>
                          State: {{ lead?.state ? lead?.state : "N/A" }} , City:
                          {{ lead?.city ? lead?.city : "N/A" }}, Street:
                          {{ lead?.street ? lead?.street : "N/A" }}, Zip Code:
                          {{ lead?.zip_code ? lead?.zip_code : "N/A" }}
                        </b-card-text>
                      </template>
                    </div>
                  </b-col>
                  <b-col
                    cols="6"
                    lg="2"
                  >
                    <div class="mt-2">
                      <h5 class="text-capitalize">
                        Country
                      </h5>
                      <template v-if="lead?.country_id">
                        <b-card-text>
                          {{ lead?.leadCountry?.data.name }}</b-card-text>
                      </template>
                    </div>
                  </b-col>
                  <b-col
                    cols="6"
                    lg="2"
                  >
                    <div class="mt-2">
                      <h5 class="text-capitalize">
                        Note
                      </h5>
                      <template v-if="lead?.note">
                        <b-card-text> {{ lead?.note }}</b-card-text>
                      </template>
                      <template v-else-if="!lead?.note">
                        <b-card-text> N/A</b-card-text>
                      </template>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="6"
                    lg="2"
                  >
                    <div class="mt-2">
                      <h5 class="text-capitalize">
                        Business Card (Front)
                      </h5>
                      <template v-if="lead?.business_card_front">
                        <b-img
                          :src="lead?.business_card_front"
                          class="small-image"
                        />
                      </template>
                      <template v-else>
                        <b-badge
                          pill
                          variant="danger"
                        >
                          N/A
                        </b-badge>
                      </template>
                    </div>
                  </b-col>
                  <b-col
                    cols="6"
                    lg="2"
                  >
                    <div class="mt-2">
                      <h5 class="text-capitalize">
                        Business Card (Back)
                      </h5>
                      <template v-if="lead?.business_card_back">
                        <b-img
                          :src="lead?.business_card_back"
                          class="small-image"
                        />
                      </template>
                      <template v-else>
                        <b-badge
                          pill
                          variant="danger"
                        >
                          N/A
                        </b-badge>
                      </template>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        lg="12"
        cols="12"
      >
        <b-card>
          <div>
            <!-- search input -->
            <template v-if="lead?.leadStatus?.data?.type == 2">
              <b-alert
                variant="success"
                show
              >
                <h4 class="alert-heading">
                  This lead has been converted successfully & locked!
                  <template v-if="lead?.estimation_sent == false">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="flex-shrink-0 ml-1"
                      variant="success"
                      @click="showEstimationModal"
                    >
                      Financial Estimation
                    </b-button></template>
                </h4>
              </b-alert>
            </template>
            <template v-else-if="lead?.leadStatus?.data?.type == 3">
              <b-alert
                variant="danger"
                show
              >
                <h4 class="alert-heading">
                  This lead has been lost & locked!
                </h4>
              </b-alert></template>
            <div
              class="custom-search d-flex align-items-center justify-content-end"
            >
              <div
                class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
              >
                <template
                  v-if="$permissionAbility(LEAD_ACTIVITY_CREATE, permissions)"
                >
                  <template v-if="lead?.leadStatus?.data?.type == 1">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="flex-shrink-0"
                      variant="primary"
                      @click="newLogModal"
                    >
                      Create Activity Log
                    </b-button>
                  </template>
                </template>
              </div>
            </div>

            <vue-good-table
              mode="remote"
              style-class="vgt-table lead-details-table striped condensed"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :rows="rows"
              :sort-options="{
                enabled: true,
                multipleColumns: true,
                initialSortBy: [{ field: 'last_contact_date', type: 'desc' }],
              }"
              :columns="columns"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- File -->

                <template v-if="props?.column?.field === 'format_file'">

                  <b-button
                    v-if="props.row?.file.length > 0"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-sm"
                    @click="showAttachmentModal(props.row?.file)"
                  >
                    <feather-icon icon="DownloadIcon" />
                  </b-button>

                  <template v-else>
                    <b>No Files To Download</b>
                  </template>
                </template>

                <template v-if="props?.column?.field === 'format_remarks'">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-sm"
                    @click="showDescriptionModal(props.row?.remarks)"
                  >
                    <feather-icon icon="InfoIcon" />
                  </b-button>
                </template>

                <template v-if="props?.column?.field === 'format_lead_status'">
                  <b-badge
                    pill
                    :style="{
                      background: props?.row?.leadStatus?.data?.color_code,
                    }"
                  >
                    {{ props?.row?.leadStatus?.data?.name }}
                  </b-badge>
                </template>
                <template v-if="props?.column?.field === 'format_created_by'">
                  {{ props?.row?.created_by?.name }}
                </template>
                <span v-if="props?.column?.field === 'action'">

                  <template v-if="$permissionAbility(LEAD_ACTIVITY_EDIT, permissions)">
                    <span @click="onShow(props.row)">
                      <feather-icon
                        v-b-tooltip.hover
                        icon="Edit2Icon"
                        class="mr-50 custom-icon cursor-pointer"
                        title="Edit"
                        variant="warning"
                        size="16"
                      />
                    </span>
                  </template>

                  <template v-if="$permissionAbility(LEAD_ACTIVITY_DELETE, permissions)">
                    <span @click="onDelete(props?.row?.id)">

                      <feather-icon
                        v-b-tooltip.hover
                        icon="TrashIcon"
                        class="mr-50 custom-icon cursor-pointer"
                        variant="danger"
                        title="Delete"
                        size="16"
                      />
                    </span>
                  </template>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props?.column?.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10', '25', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      of {{ props.total }} entries
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props?.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
          <b-modal
            id="description-modal"
            centered
            title="Remarks"
            hide-footer
            no-close-on-backdrop
            @hidden="hiddenDescriptionModal"
          >
            <b-card-text>
              {{ modalDescription }}
            </b-card-text>
          </b-modal>

          <b-modal
            id="attachment-modal"
            centered
            title="Attachments"
            hide-footer
            no-close-on-backdrop
            @hidden="hiddenAttachmentModal"
          >
            <b-card-text v-if="selectedAttachments && Array.isArray(selectedAttachments)">
              <b-row>
                <b-col
                  v-for="(fileUrl, index) in selectedAttachments"
                  :key="index"
                  class="mb-1"
                  cols="6"
                >
                  <div class="d-flex align-items-center justify-content-between">
                    <h5 class="mb-0">
                      <b-link
                        :href="fileUrl.original_url"
                        target="_blank"
                        class="d-flex align-items-center"
                      >
                        <feather-icon
                          icon="DownloadIcon"
                          class="mr-2"
                        />
                        Attachment
                      </b-link>
                    </h5>

                    <b-button
                      variant="danger"
                      size="sm"
                      class="ml-2"
                      @click="deleteAttachment(fileUrl)"
                    >
                      X
                    </b-button>
                  </div>
                </b-col>
              </b-row>

            </b-card-text>

          </b-modal>
          <!-- Activity Log Modal -->
          <b-modal
            id="modal-activity-log-show-form"
            centered
            :title="
              modelType == 'editModel'
                ? 'Edit Activity Log'
                : 'Create Activity Log '
            "
            hide-footer
            no-close-on-backdrop
            @hidden="hiddenModal"
          >
            <validation-observer ref="leadActvityLogValidation">
              <b-form @submit.prevent="validationForm">
                <b-row>
                  <template
                    v-if="$permissionAbility(LEAD_ACTIVITY_EDIT, permissions)"
                  >
                    <b-col
                      md="10"
                      lg="10"
                      xs="12"
                    >
                      <b-form-group
                        label="Lead Status "
                        label-for="leadStatusId"
                        class="required-label"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="lead status"
                          vid="lead_status_id"
                          rules="required"
                        >
                          <v-select
                            id="division-id"
                            v-model="leadStatusId"
                            placeholder="Assign a Status"
                            :options="statusIdOption"
                            :reduce="(country) => country.id"
                            label="name"
                            @input="checkForFinalStatus"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      md="2"
                      lg="2"
                      xs="12"
                    >
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top="'Reset Status List!'"
                        variant="outline-secondary"
                        class="btn-icon rounded-circle mt-2"
                        @click="loadAllStatus"
                      >
                        <feather-icon icon="RefreshCcwIcon" />
                      </b-button>
                    </b-col>
                  </template>
                  <template
                    v-if="!$permissionAbility(LEAD_ACTIVITY_EDIT, permissions)"
                  >
                    <b-col
                      md="12"
                      lg="12"
                      xs="12"
                    >
                      <b-form-group
                        label="Lead Status *"
                        label-for="leadStatusId"
                      >
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="lead status"
                          vid="lead_status_id"
                          rules="required"
                        >
                          <v-select
                            id="division-id"
                            v-model="leadStatusId"
                            placeholder="Assign a Status"
                            :options="statusIdOption"
                            :reduce="(item) => item.id"
                            label="name"
                            @input="checkForFinalStatus"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col></template>
                </b-row>

                <!-- select contact medium -->
                <b-form-group
                  label="Contact Medium "
                  label-for="contact-through"
                  class="required-label"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="contact medium"
                    vid="contact_through"
                    rules="required"
                  >
                    <v-select
                      id="contact-through"
                      v-model="contactMedium"
                      :options="selectContactMediumOption"
                      :reduce="(medium) => medium.value"
                      placeholder="Choose A Contact Medium"
                      label="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>

                <!-- remarks -->
                <b-form-group
                  label="Remarks "
                  label-for="remarks"
                  class="required-label"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="remarks"
                    vid="remarks"
                    rules="required"
                  >
                    <b-form-textarea
                      id="remarks"
                      v-model="remarks"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Meeting Remarks"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>

                <!-- file -->
                <b-form-group
                  :label="selectedLeadStatus?.type === 2 ? 'Project Proposal' : 'Attach File'"
                  label-for="file"
                  :class="{'required-label': selectedLeadStatus?.type === 2}"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="attachment"
                    :rules="fileValidationRules"
                    vid="lead_activity_log_file"
                  >
                    <b-form-file
                      :id="`file-${selectedLeadStatus?.type}`"
                      v-model="file"
                      multiple
                      :state="errors.length ? false : null"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      @change="handleFileChange"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Next Contact Date & Time"
                  label-for="next_contact_date"
                  :class="{ 'required-label': selectedLeadStatus?.type !== 2 && selectedLeadStatus?.type !== 3 }"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="next contact date"
                    vid="next_contact_date"
                    :rules="selectedLeadStatus?.type !== 2 && selectedLeadStatus?.type !== 3 ? 'required' : ''"
                  >
                    <flat-pickr
                      id="next-contact-date"
                      v-model="nextContactDate"
                      class="form-control"
                      :state="errors.length > 0 ? false : null"
                      :config="contactDateConfig"
                      placeholder="Select Date & Time"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>

                <!-- loading button -->
                <template v-if="isLeadActivityFormSubmitLoading">
                  <b-button
                    class="float-right"
                    variant="primary"
                    disabled
                  >
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    class="float-right"
                    variant="primary"
                  >
                    Submit
                  </b-button>
                </template>
              </b-form>
            </validation-observer>
          </b-modal>

          <!-- Financial Estimation Modal -->

          <FinancialEstimationForm
            :selected-lead-info="selectedLeadInfo"
            :lead-title="leadTitle"
            @loadLeadInfo="updateLeadInfo($event)"
          />
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
  <!--  -->
<script>
import {
  BLink,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BButton,
  BForm,
  BModal,
  BCol,
  BRow,
  BCardText,
  BFormTextarea,
  BSpinner,
  BAlert,
  VBTooltip,
  BFormDatepicker, BImg,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import FinancialEstimationForm from '@/views/admin/finance/project/FinancialEstimationForm.vue'
import {
  required, max, size, ext, max_value, min_value,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import { projectTypeConstants } from '@/helpers/constant/projectTypeConstant'
import { paymentTypeConstants } from '@/helpers/constant/paymentTypeConstant'
import { leadStatusTypeConstants } from '@/helpers/constant/leadStatusTypeConstant'
import {
  LEAD_ACTIVITY_ACCESS,
  LEAD_ACTIVITY_CREATE,
  LEAD_ACTIVITY_EDIT,
  LEAD_ACTIVITY_DELETE,
} from '@/helpers/permissionsConstant'
import UserCard from '@/layouts/components/UserCard.vue'
import { formatDateRange } from '@/helpers/helpers'

export default {
  name: 'LeadDetailsView',
  components: {
    BImg,
    FinancialEstimationForm,
    BLink,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    UserCard,
    BPagination,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCol,
    BRow,
    BCardText,
    BSpinner,
    BFormTextarea,
    flatPickr,
    BAlert,
    BFormDatepicker,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      LEAD_ACTIVITY_ACCESS,
      LEAD_ACTIVITY_CREATE,
      LEAD_ACTIVITY_EDIT,
      LEAD_ACTIVITY_DELETE,
      projectTypeConstants,
      paymentTypeConstants,
      leadStatusTypeConstants,
      selectedAttachments: null,
      modalDescription: '',
      projectType: '',
      paymentType: '',
      amount: '',
      timeDuration: '',
      milestones: '',
      selectedLeadInfo: {},
      milestoneAmmount: [],
      milestoneDeadLine: [],
      milestonePaymentType: [],

      contactDateConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i:S',
        static: true,
        minuteIncrement: 1,
      },

      leadId: this.$route.params.id,
      leadTitle: '',
      leadActivityLogId: '',
      modelType: '',
      contactMedium: '',
      nextContactDate: '',
      selectContactMediumOption: [
        {
          value: 'Face To Face Meeting',
          text: 'Through a Face To Face Meeting',
        },
        { value: 'Virtual Meeting', text: 'Through a Virtual Meeting' },
        { value: 'Email', text: 'Through an Email' },
        { value: 'Call', text: 'Through a Phone Call' },
        { value: 'Text', text: 'Through a Text' },
        { value: 'Proposal Sent', text: 'Though Sending Proposal' },
      ],
      remarks: '',
      lastContactDate: '',
      nextContactDate: '',
      statusIdOption: [],
      leadStatusId: '',
      selectedLeadStatus: '',
      file: [],

      pageLength: 10,
      columns: [
        {
          label: 'Contact Medium',
          field: 'contact_through',
          sortable: false,
        },
        {
          label: 'Remarks',
          field: 'format_remarks',
          sortable: false,
        },
        {
          label: 'File',
          field: 'format_file',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'format_lead_status',
          sortable: false,
        },

        {
          label: 'Last Contact Date & Time',
          field: 'last_contact_date',
          formatFn: this.formatDate,
          sortable: false,
        },
        {
          label: 'Next Contact Date & Time',
          field: 'next_contact_date',
          formatFn: this.formatDate,
          sortable: false,
        },
        {
          label: 'Created By',
          field: 'format_created_by',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      delayTimer: null,
      isLoading: false,
      isMileStoneLoading: false,
      isLeadActivityFormSubmitLoading: false,
      isFinancialEstimationFormLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: 'id', type: 'desc' },
          { field: 'name', type: 'desc' },
          { field: 'created_at', type: 'desc' },
        ],
        page: 1,
        perPage: 10,
      },
      lead: {},
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'userModule/getPermissions',
    }),

    fileValidationRules() {
      return this.selectedLeadStatus?.type === 2
        ? 'required|size:8048|ext:jpg,jpeg,png,pdf,csv,xlsx,txt,docx'
        : 'size:8048|ext:jpg,jpeg,png,pdf,csv,xlsx,txt,docx'
    },
  },

  async created() {
    this.loadLeadData()
  },
  methods: {

    handleFileChange(event) {
      this.file = Array.from(event.target.files)
    },

    async deleteAttachment(attachment) {
      await this.$api.delete(`/api/lead-activity-media/${attachment?.model_id}/${attachment?.id}`)

      this.selectedAttachments = this.selectedAttachments.filter(item => item.id !== attachment.id)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'BellIcon',
          variant: 'success',
          text: 'Attachment deleted successfully',
        },
      })

      this.$bvModal.hide('attachment-modal')
      this.loadItems()
    },
    checkForFinalStatus() {
      this.selectedLeadStatus = (this.statusIdOption || []).find(item => {
        if (item?.type == 2 && item?.id == this.leadStatusId) {
          return item
        } if (item?.type == 3 && item?.id == this.leadStatusId) {
          return item
        }
      })
    },
    showDescriptionModal(remarks) {
      if (remarks) {
        this.modalDescription = remarks
      } else {
        this.modalDescription = 'N/A'
      }

      this.$bvModal.show('description-modal')
    },

    showAttachmentModal(attachment) {
      this.selectedAttachments = attachment
      this.$bvModal.show('attachment-modal')
    },
    hiddenDescriptionModal() {
      this.$bvModal.hide('description-modal')
    },
    hiddenAttachmentModal() {
      this.selectedAttachments = null
      this.$bvModal.hide('attachment-modal')
    },
    async loadLeadData() {
      try {
        const { id } = this.$route.params
        const resLead = await this.$api.get(
          `api/leads/${id}?include=user,leadCategory,leadSource,leadStatus,department,leadCountry,dialCodeCountry,team`,
        )

        const dataLead = resLead?.data?.data

        this.leadTitle = dataLead?.title

        this.lead = dataLead
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }

      this.loadAvailableStatus()
    },
    updateMilestonePaymentType() {
      this.milestonePaymentType = []
      for (let i = 0; i < this.milestones; i++) {
        this.milestonePaymentType.push(this.paymentType)
      }
    },

    loadMileStone() {
      this.milestoneAmmount = []
      this.milestonePaymentType = []
      for (let i = 0; i < this.milestones; i++) {
        this.milestoneAmmount.push(this.amount / this.milestones)
        this.milestonePaymentType.push(this.paymentType)
      }
      this.isMileStoneLoading = false
      return true
    },

    updateMilestoneInfo() {
      this.isMileStoneLoading = true
      setTimeout(this.loadMileStone, 300)
    },
    async loadAllStatus() {
      // status
      const status = await this.$api.get('api/lead-status/active-all')
      this.statusIdOption = (status?.data?.data || []).map(item => {
        let position = ''

        if (item?.type == 2) {
          position = '(Success)'
        } else if (item?.type == 3) {
          position = '(Dead)'
        }
        const name = `${item?.name} ${position}`
        return {
          name,
          id: item.id,
        }
      })
    },
    async loadAvailableStatus() {
      try {
        const [status] = await Promise.all([this.getLeadStatus()])

        const existingStatusId = this.lead?.leadStatus?.data?.sequence

        let availableStatus = []
        availableStatus = (status?.data?.data || []).filter(item => {
          if (item?.sequence >= existingStatusId) {
            const { name } = item
            return {
              name,
              id: item.id,
            }
          }
        })
        this.statusIdOption = availableStatus
        this.statusIdOption = (availableStatus || []).map(item => {
          let position = ''

          if (item?.type == 2) {
            position = '(Success)'
          } else if (item?.type == 3) {
            position = '(Dead)'
          }
          const name = `${item?.name} ${position}`
          return {
            name,
            type: item?.type,
            id: item.id,
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },
    formatFnTableLastContactDate(value) {
      if (value) {
        return this.$moment(value).format('MMM Do YYYY')
      }
    },
    formatFnTableNextContactDate(value) {
      if (value) {
        return this.$moment(value).format('MMM Do YYYY')
      }
      return 'N/A'
    },

    onSearchUsers(search, loading) {
      if (search.length) {
        const { id } = this.$route.params
        loading(true)
        if (this.delayTimer) {
          clearTimeout(this.delayTimer)
          this.delayTimer = null
        }

        this.delayTimer = setTimeout(async () => {
          const resUsers = await this.$api.get(
            `api/department/${id}/users/search?include=department`,
            {
              params: {
                q: search,
              },
            },
          )

          const dataUsers = resUsers?.data?.data
          this.usersOption = (dataUsers || []).map(item => {
            let { name } = item
            let department = null

            if (item.department_id) {
              name = `${item.name} (${item?.department?.data?.name})`
              department = item?.department?.data?.name
            }

            return {
              name,
              id: item.id,
              department,
            }
          })
          loading(false)
        }, 800)
      }
    },
    async onShow(value) {
      this.modelType = 'editModel'
      this.leadActivityLogId = value?.id
      this.contactMedium = value?.contact_through
      this.remarks = value?.remarks
      this.nextContactDate = value?.next_contact_date
      this.leadStatusId = this.lead?.lead_status_id
      this.showModal()
    },
    showModal() {
      this.leadStatusId = this.lead?.leadStatus?.data?.id
      this.$bvModal.show('modal-activity-log-show-form')
      this.loadAvailableStatus()
    },

    newLogModal() {
      this.modelType = ''
      this.showModal()
    },

    showEstimationModal() {
      this.selectedLeadInfo = {
        leadId: this.lead?.id,
        departmentId: this.lead?.department_id,
      }

      this.$bvModal.show('modal-financial-estimation-show-form-on-lead')
    },

    hideEstimationModal() {
      this.$bvModal.hide('modal-financial-estimation-show-form')
      this.resetEstimationModal()
    },
    resetEstimationModal() {
      this.projectType = ''
      this.amount = ''
      this.timeDuration = ''
      this.milestones = ''
      this.paymentType = ''
      this.milestoneAmmount = []
      this.milestoneDeadLine = []
      this.milestonePaymentType = []
    },
    hiddenModal() {
      this.$bvModal.hide('modal-activity-log-show-form')
      this.resetModal()
    },
    resetModal() {
      this.leadId = ''
      this.leadActivityLogId = ''
      this.contactMedium = ''
      this.file = []
      this.remarks = ''
      this.nextContactDate = ''
      this.leadStatusId = ''
      this.selectedLeadStatus = ''
    },

    async getLeadAcitvityLog(params) {
      this.leadId = this.$route.params.id
      return await this.$api.get(
        `api/leads/${this.leadId}/lead-activity-log?include=leadStatus`,
        {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            q: params.q,
          },
        },
      )
    },
    updateLeadInfo() {
      this.loadLeadData()
      this.showFinancialEstimationComponent = false
    },
    async getLeadStatus() {
      return await this.$api.get('api/lead-status/active-all')
    },
    async onDelete(id) {
      this.$swal({
        title: 'Warning!',
        text: 'Are You Sure You Want To Delete This?',
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: 'Ok',
        showLoaderOnConfirm: true,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/lead-activity-log/${id}`)

            this.loadItems()

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Successfully Deleted',
              },
            })
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                variant: 'danger',
                text: error?.response?.data?.message,
              },
            })
          }
        }
      })
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer)
        this.delayTimer = null
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems()
      }, 1000)
    },

    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    async loadItems() {
      try {
        const [leadAcitvityLog] = await Promise.all([
          this.getLeadAcitvityLog({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
          }),
        ])

        const data = leadAcitvityLog?.data?.data

        const meta = leadAcitvityLog?.data?.meta

        this.totalRecords = meta?.pagination?.total
        this.rows = data
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },

    async validationForm() {
      const validateForm = async () => this.$refs.leadActvityLogValidation.validate()

      const resetForm = () => {
        this.$refs.leadActvityLogValidation.reset()
      }

      const showToast = (title, text, variant = 'success') => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title, icon: 'BellIcon', variant, text,
          },
        })
      }

      const fetchLeadData = async () => {
        try {
          const { id } = this.$route.params
          const resLead = await this.$api.get(
            `api/leads/${id}?include=user,leadCategory,leadSource,leadStatus,department,leadCountry,dialCodeCountry`,
          )
          this.lead = resLead?.data?.data
        } catch (error) {
          showToast('Error', error?.response?.data?.message, 'danger')
        }
      }

      const prepareFormData = () => {
        const formData = new FormData()

        if (this.modelType === 'editModel') {
          formData.append('_method', 'PUT')
        }

        if (this.leadId) formData.append('lead_id', this.leadId)
        if (this.contactMedium) formData.append('contact_through', this.contactMedium)
        if (this.remarks) formData.append('remarks', this.remarks)
        if (this.leadStatusId) formData.append('lead_status_id', this.leadStatusId)

        formData.append(
          'lead_status_type',
            this.selectedLeadStatus?.type || this.leadStatusTypeConstants[0]?.value,
        )

        this.file.forEach((file, index) => {
          formData.append(`lead_activity_log_file[${index}]`, file)
        })

        formData.append('next_contact_date', this.nextContactDate)

        return formData
      }

      const submitForm = async formData => {
        const url = this.modelType === 'editModel'
          ? `/api/lead-activity-log/${this.leadActivityLogId}`
          : '/api/lead-activity-log'

        await this.$api.post(url, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })

        showToast('Success', 'Lead Activity Log Submitted Successfully')
      }

      try {
        if (await validateForm()) {
          resetForm()
          this.isLeadActivityFormSubmitLoading = true
          this.leadId = this.$route.params.id

          const formData = prepareFormData()
          await submitForm(formData)

          this.isLeadActivityFormSubmitLoading = false
          this.hiddenModal()
          this.loadItems()
          await fetchLeadData()
          this.loadAvailableStatus()
        }
      } catch (error) {
        this.isLeadActivityFormSubmitLoading = false

        if (error?.response?.data?.message) {
          showToast('Error', error?.response?.data?.message, 'danger')
        }

        if (error?.response?.data?.errors) {
          this.$refs.leadActvityLogValidation.setErrors(error?.response?.data?.errors)
        }
      }
    },

  },
}
</script>

  <style lang="scss">
.custom-font {
  font-size: 11px !important;
}
.lead-details-table {
  // font-size: 13px !important;
  // font-weight: 600 !important;
  min-height: 140px !important;
  white-space: nowrap !important;
  font-size: 13px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}

.required-label label::after {
  content: " *";
  color: red;
}

@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.small-image {
  max-width: 150px; /* Limits the width to make the image smaller */
  height: auto; /* Maintains the aspect ratio */
}

.flatpickr-wrapper{
  width: 100% !important;
}
</style>
